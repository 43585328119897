<template>
	<div class="label-list">
		<el-form :inline="true" class="form-content">

			<el-form-item>

				<el-input placeholder="请输入测评套餐名称关键字查询" v-model="search" class="input-with-select" style="width: 400px">
				
					<el-button slot="append" @click="getList" icon="el-icon-search"></el-button>
				</el-input>
			</el-form-item>
			
			<el-form-item label="设备型号">
			<el-select value-key = "id"  placeholder="请选择" v-model="deviceType" clearable filterable  class="wd300">
				<el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
				</el-option>
			</el-select>
			</el-form-item>
	
		
			<el-form-item>
				<el-button @click="getList()" type="primary">搜索</el-button>
				<el-button @click="reset()" type="reset">重置</el-button>
			</el-form-item>
		</el-form>
		<div class="search-list">
		  <el-button type="primary" @click="add">套餐维护</el-button>
		  <el-button
		    type="danger"
		    @click="deleteHandle"
		    :disabled="dataListSelections.length ? false : true"
		    >删除</el-button
		  >
		</div>
		<div class="table">
			<el-table :data="dataList" style="width: 100%" v-loading="dataListLoading"
				@selection-change="selectionChangeHandle">
				<el-table-column type="selection" width="50"></el-table-column>
			 


				<el-table-column min-width="120"  label="设备型号">
<template slot-scope="scope">
					<div style="padding: 11px;">{{scope.row.deviceTypeName.typeName}}</div>
					</template>
				</el-table-column>
				<el-table-column min-width="180" prop="evaluationPackage.packageName" label="测评套餐名称"></el-table-column>
			 
				 <el-table-column min-width="180"     label="排序权重">
					<template slot-scope="scope">
						<div v-if="scope.row.orderWeightEdit" style="display:flex;align-items:center;">
					 	<div style="padding: 11px;">{{scope.row.orderWeight}} <img @click="changeOrderWeightEdit(scope.row, scope.$index)" class="icon" src="../../assets/img/edit2.png" mode=""></img> </div>				 
						</div>
						<el-input @blur="changeOrderWeight(scope.row, scope.$index)" v-else placeholder="请输入 " v-model="scope.row.orderWeight"  style="width: 100px">
						</el-input>
					</template>
				 </el-table-column>
				 
				<el-table-column min-width="180"  prop="createTime" label="添加时间"></el-table-column>
				

				<el-table-column min-width="120"  prop="judgeUserName"  label="操作人" >
					 
				</el-table-column>
			</el-table>
			<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
				:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
				layout="total, sizes, prev, pager, next, jumper"></el-pagination>
		</div>
		<el-dialog title="设备套餐维护" :visible.sync="visible" width="800px" :close-on-click-modal="false">
		
			<el-form>
				<el-form-item label="套餐名称" required>
					<el-select placeholder="请选择" v-model="form.pacIds" multiple  clearable style="width:300px;">
						 
						 <el-option v-for="item in pacList" :key="item.id" :label="item.packageName" :value="item.id">
						 </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="设备型号"   required>
					 
					<el-select value-key = "id"  placeholder="请选择" v-model="form.typeIds" clearable filterable multiple class="wd300">
						<el-option v-for="item in typeList" :key="item.id" :label="item.typeName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				 
			</el-form>
		
		
		
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submit">保存</el-button>
				<el-button @click="visible = false">取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import Qs from "qs";
	import moment from "moment";
	export default {
		name: "label",
		data() {
			return {
				search: "",
				select: 1,
				taskId: '',
				status: "",
				dialogVisible: false,
				dataList: [],
				pacList: [],
				dataListLoading: true, //列表loading
				dataListSelections: [], //选中的列
				pageNo: 1,
				pageSize: 10,
				deviceType: '',
				totalPage: 0, //总页数
				dateRangeTime: [],
				teamsManage: [],
				typeList: [

				],
				visible: false,
				form: {
typeIds:'',
pacIds:'',
				},
				dataForm: {
					totalScore: '',
					scoreAnalysis: '',
					totalResult: '',
					scoreAdvice: '',
				},
				formList: '',
				dataRules: {
					title: [{
						required: true,
						message: "请输入任务主题",
						trigger: "blur"
					}],
				},
				edit: false,
				flag: true, //防止重复点击
				visible2: false,
				dataList2: [],
				dataListLoading2: true, //列表loading
				dataListSelections2: [], //选中的列
				pageNo2: 1,
				pageSize2: 10,
				totalPage2: 0, //总页数
			};
		},
		async mounted() {

	await this.getTypeList();
			await this.getList();
			await this.getPacList();
			
		},
		methods: {
			async showScore(itemObj) {
				this.dataForm.totalScore = itemObj.totalScore
				this.taskId = itemObj.id
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/evaluationPackage/info"),
					method: "post",
					data: {
						stringParam1: itemObj.packageId
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.form = res.data;

					if (res.data.packageRule && res.data.packageRule.length > 0) {
						this.formList = JSON.parse(res.data.packageRule)
						console.log(this.formList)
						if (itemObj.totalResult && itemObj.totalResult.length > 0) {
							this.dataForm.totalResult = itemObj.totalResult
							this.dataForm.scoreAnalysis = itemObj.scoreAnalysis
							this.dataForm.scoreAdvice = itemObj.scoreAdvice
						} else {
							for (let item of this.formList) {
								if (this.dataForm.totalScore >= item.score1 && this.dataForm.totalScore <= item
									.score2) {

									this.dataForm.totalResult = item.cpjg
									this.dataForm.scoreAnalysis = item.jgfx
									this.dataForm.scoreAdvice = item.tzjy
								}
							}
						}

					}

					this.dialogVisible = true


				}
				this.dialogVisible = true
			},
			resetScore(val) {

				if (parseInt(this.dataForm.totalScore) < parseInt(this.formList[0].score1) || parseInt(this.dataForm
						.totalScore) > parseInt(this.formList[this.formList.length - 1].score2)) {
					this.$message.error('请正确填写评分')
					this.dataForm.totalScore = ''
					return
				}
				for (let item of this.formList) {
					if (parseInt(this.dataForm.totalScore) >= parseInt(item.score1) && parseInt(this.dataForm
						.totalScore) <= parseInt(item.score2)) {

						this.dataForm.totalResult = item.cpjg
						this.dataForm.scoreAnalysis = item.jgfx
						this.dataForm.scoreAdvice = item.tzjy
					}
				}
			},
			changeOrderWeightEdit(item,index){
				this.dataList[index].orderWeightEdit = false
			},
			async changeOrderWeight(item,index){
			 
				let obj = {
					orderWeight:item.orderWeight ,
					id:item.id
				}
				console.log(obj)
			
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						 "/deviceTypePackage/update"  
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
							
					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
				 		this.$message.success(res.msg);
				 this.getList()
				
							
				}
			},
			async getList() {
				let stringParam1 = ''
				let stringParam2 = ''
				 
				let params = {

					stringParam1: this.search,
					stringParam2: this.deviceType,
					 

					pageSize: this.pageSize,
					pageNo: this.pageNo,
				};

				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/deviceTypePackage/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					res.data.forEach((item)=>{
						item.orderWeightEdit = true
						item.createTime = moment(new Date(item.createTime)).format(
							"YYYY-MM-DD HH:mm:ss"
						)
					})
					this.dataList = res.data;
					this.totalPage = res.totalCount;
					console.log(this.dataList)
					
				}
				this.dataListLoading = false;
			},
		async getPacList() {
		
		  let params = {
		
 
		    pageSize: 9999,
		    pageNo: 1,
		  };
		
		  const { data: res } = await this.$httpAes({
		    url: this.$httpAes.adornUrl("/evaluationPackage/list"),
		    method: "post",
		    data: params,
			contentType:'json',
			headers: {
				'Content-Type': 'application/json',
			   				
			},
		  });
		  if (res.status) {
		    this.pacList = res.data;}
		    
		},
			async	submit() {
				 
	if( this.form.pacIds==''){
				this.$message.error('请选择套餐')
				return
			}
			if( this.form.typeIds==''){
				this.$message.error('请选择设备型号')
				return
			}
			let obj = {
				stringParam1:this.form.pacIds.join(','),
				stringParam2:this.form.typeIds.join(','),
			}
							const {
								data: res
							} = await this.$httpAes({
								url: this.$httpAes.adornUrl(
									this.form.id ? "/deviceTypePackage/update" :
									"/deviceTypePackage/add "
								),
								method: "post",
								contentType: 'json',
								headers: {
									'Content-Type': 'application/json',
			
								},
								data: obj,
							});
							console.log(res);
							if (res.status) {
							 		this.$message.success(res.msg);
							 this.getList()
							 this.visible = false
			
							}
					
				},
			isSameDay(date1, date2) {
				return (
					date1.getFullYear() === date2.getFullYear() && // 年份相等
					date1.getMonth() === date2.getMonth() && // 月份相等
					date1.getDate() === date2.getDate() // 日期相等
				);
			},
			gotoInfo(item) {
				this.$router.push({
					name: "modelInfo",
					query: {
						id: item.id
					},
				});

			},
			add() {
				  this.form.typeIds='' 
				   this.form.pacIds='' 

					this.visible = true

				

			},
			cancel() {
				this.$refs["form"].resetFields();
				this.visible = false;
				this.form = {
					title: "",
				};
			},
			gotoDetail(item) {
				sessionStorage.evaluationRecDetail = JSON.stringify(item)
				this.$router.push({
					name: "evaluationRecDetail",
					query: {
						id: item.id
					},
				});
			},
			async dataFormSubmit(status) {
				if (this.dataForm.totalScore == '' || this.dataForm.totalResult == '' || this.dataForm.scoreAnalysis ==
					'' || this.dataForm.scoreAdvice == '') {
					this.$message.error('请填写完所有评分项')
					return
				}

				let obj = {
					id: this.taskId,
					totalScore: this.dataForm.totalScore,
					totalResult: this.dataForm.totalResult,
					scoreAnalysis: this.dataForm.scoreAnalysis,
					scoreAdvice: this.dataForm.scoreAdvice,
					publishStatus: status,
					judgeUserId: JSON.parse(sessionStorage.userInfo).id
				}
				if (status == 1) {
					obj.publishTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
					obj.resultTime = moment(new Date()).format(
						"YYYY-MM-DD HH:mm:ss"
					)
				}
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						"/diagnosisTask/update"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: obj,
				});
				console.log(res);
				if (res.status) {
					this.$message({
						message: res.msg,
						type: "success",
						duration: 1500,
						onClose: () => {
							this.dialogVisible = false
							this.getList();
						},
					});


				}
			},

			async getTypeList() {
				let params = {
					pageSize: 9999,
					pageNo: 1,
				};
				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("deviceType/list"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
				
					},
				});
				 console.log(res,'设备型号')
				this.typeList = res.data;
			},

			async deleteHandle() {
				let that = this;
				let ids = '';

				ids = that.dataListSelections.map((item) => {
					return item.id;
				});
				ids = ids.join(",");

				that
					.$confirm(`确定进行删除操作?`, "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {

						that
							.$httpAes({
								url: this.$httpAes.adornUrl("/deviceTypePackage/delete"),
								method: "post",
								data: {
									stringParam1: ids
								},
								contentType: 'json',
								headers: {
									'Content-Type': 'application/json',

								},
							})
							.then(({
								data
							}) => {
								if (data.status) {
									that.$message({
										message: data.msg,
										type: "success",
										duration: 1500,
										onClose: () => {
											that.getList();
										},
									});
								} else {
									that.$message.error(data.msg);
								}
							});



					})
					.catch(() => {});
			},
			reset() {

				this.deviceType = ''

				this.search = ''
				this.getList();
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val;
				this.pageNo = 1;
				this.getList();
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageNo = val;
				this.getList();
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val;
			},
			// 显示下载列表
			downloadData(row) {
				this.visible2 = true;
				this.getDataList(row.id);
			},
			// 下载内容
			async getDataList(id) {
				let params = {
					pageSize: this.pageSize2,
					pageNo: this.pageNo2,
					retrievalId: id,
				};
				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl("/mbglRetrieveRecords/list"),
					method: "get",
					params: params,
				});
				if (res.status) {
					this.dataList2 = res.data;
					this.totalPage2 = res.totalCount;
				}
				this.dataListLoading2 = false;
			},
			// 每页数
			sizeChangeHandle2(val) {
				this.pageSize2 = val;
				this.pageNo2 = 1;
				this.getDataList();
			},
			// 当前页
			currentChangeHandle2(val) {
				this.pageNo2 = val;
				this.getDataList();
			},
			// 添加检索
			addQuestionSearch(row) {
				let params = {
					retrievalId: row.id
				};
				this.$http({
					url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
					method: "post",
					data: Qs.stringify(params),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.updateStatus(row.id);
					}
				});
			},
			updateStatus(id) {
				let params = {
					ids: id,
					status: 3
				};
				this.$http({
					url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
					method: "post",
					data: Qs.stringify(params),
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: "重新检索成功",
							type: "success",
							duration: 1500,
							onClose: () => {
								this.$router.push({
									name: "caseSearch",
								});
							},
						});
					}
				});
			},
			// 下载
			downloadUrl(row) {
				var that = this;
				let link = document.createElement("a");
				link.href = row.url;
				link.click();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-select .el-input {
		width: 130px;
	}

	.top {
		display: flex;

		.left {
			width: 50%;
		}

		.right {
			flex: 1;
		}
	}

	.scoreContent {
		font-size: 14px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 22px;
		width: 100%;
		height: 54px;
		background: #F8F3FF;
		box-sizing: border-box;
		padding: 16px;
		margin-bottom: 24px;
	}
</style>